import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";

const ProjectContext = createContext();

export const ProjectProvider = ({ children, projectId, doChangeTab }) => {
  // Memoize the value object to prevent unnecessary re-renders - will only update if projectId or doChangeTab changes
  const value = useMemo(
    () => ({ projectId, doChangeTab }),
    [projectId, doChangeTab]
  );

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

ProjectProvider.propTypes = {
  children: PropTypes.node,
  projectId: PropTypes.number,
  doChangeTab: PropTypes.func,
};

export const useProjectContext = () => useContext(ProjectContext);