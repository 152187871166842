import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Drawer } from "@material-tailwind/react";
import MenuLinks from './MenuLinks';
import MenuLinksAdmin from './MenuLinksAdmin';
import { RiExpandLeftRightLine } from "react-icons/ri";

import useUser from '../../datahooks/useUser';

/**
 * SideBar component that displays a sidebar with dynamic project items
 * @param {Object} props - The properties passed to the component
 * @param {Function} props.handleClick - Function to handle click events on sidebar items
 * @param {Boolean} props.drawerOpen - Boolean to control the opening and closing of the Drawer from outside
 * @returns {JSX.Element} The SideBar component
 */
export default function SideBar({ handleClick, drawerOpen, isAdmin = false }) {
  const { user: userDetails } = useUser();
  const [isCollapsed, setIsCollapsed] = useState(() => {
    // Retrieve the menu state from localStorage if available, otherwise default to false
    return JSON.parse(localStorage.getItem('isCollapsed')) || false;
  });

  const toggleSidebar = () => {
    setIsCollapsed((prevState) => {
      const newState = !prevState;
      localStorage.setItem('isCollapsed', JSON.stringify(newState));
      return newState;
    });
  };

  useEffect(() => {
    // Store the current state in localStorage whenever it changes
    localStorage.setItem('isCollapsed', JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  return (
    <div data-testid='sidebar' className='relative border-r border-default dark:border-dark-tremor-border shadow-none'>
      {/* Toggle Button */}
      
      <div data-testid="bt-menu-expand" className='absolute top-[-1rem] right-[-1rem] z-50 bg-white border-2 rounded hidden lg:block'>
        <RiExpandLeftRightLine 
          onClick={toggleSidebar}
          size="25"
        />
      </div>

      {/* Drawer for smaller screens */}
      <Drawer 
        data-testid='sidebar-drawer' 
        shadow="false" 
        blur="false" 
        open={drawerOpen} 
        onClose={() => handleClick(false)} 
        className="p-4"
      >
        {isAdmin && (userDetails?.user_type === 'super_admin' || userDetails?.user_type === 'admin') ? <MenuLinksAdmin inDrawer /> : <MenuLinks inDrawer />}
      </Drawer>

      {/* Collapsible Sidebar for larger screens */}
      <Card 
        data-testid='sidebar-menu' 
        className={`hidden lg:block w-full bg-transparent shadow-none h-[calc(100vh-2rem)] p-1 ${isCollapsed ? 'lg:w-[4.5rem]' : 'lg:w-64'}`}
      >
        {isAdmin && (userDetails?.user_type === 'super_admin' || userDetails?.user_type === 'admin') ? <MenuLinksAdmin isCollapsed={isCollapsed} /> : <MenuLinks isCollapsed={isCollapsed} />}
      </Card>
    </div>
  );
}

SideBar.propTypes = {
  handleClick: PropTypes.func,
  drawerOpen: PropTypes.bool,
  isAdmin: PropTypes.bool,
};