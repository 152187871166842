import React from 'react';
import PopExplanation from './PopExplanation';
import PropTypes from 'prop-types';

export default function PopAPIIntegration({ page = false, size }) {

    return (
        <PopExplanation 
            title="API Integration" 
            size={size}
            metric1Title={page ? "" : "Pass Rate"}
            metric1Desc={page ? "Focuses on testing the applications APIs, ensuring that the data sent and received (payloads and responses) are correct and behave as expected. This is crucial for smooth communication between our app and other services." : "Reflects the success rate of API tests, with a high percentage indicating robust and reliable API performance, and a low percentage signaling potential issues or inconsistencies in API functionality."}
            metric2Title={page ? "" : "Total APIs Tested"}
            metric2Desc={page ? "" : "Represents the total count of APIs that have undergone testing, providing insight into the breadth of testing and the scope of API functionality evaluated."} />
    );
}

PopAPIIntegration.propTypes = {
    page: PropTypes.bool,
    size: PropTypes.number
}