import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Popover, PopoverContent, PopoverHandler, IconButton } from "@material-tailwind/react";
import { MdInfo } from "react-icons/md";

export default function PopExplanation( { title, metric1Title, metric1Desc, metric2Title, metric2Desc, size = 35 }) {

    return (
        <Popover className="flex">
            <PopoverHandler>
                <IconButton variant="text" title={title}>
                    <MdInfo key={`mdinfo-${title}-${size}`} size={size} className="text-blue-500 opacity-75" />
                </IconButton>
            </PopoverHandler>
            <PopoverContent className="z-50 w-72 md:w-96">
                <Typography variant="lead" className="border-b-2 pb-2 mb-2 w-300">{title}</Typography>
                
                {metric1Title && <Typography variant="lead" className="font-medium mt-3">{metric1Title}</Typography> }
                {metric1Desc && <Typography variant="paragraph"> {metric1Desc}</Typography> }

                {metric2Title && <Typography variant="lead" className="font-medium mt-3">{metric2Title}</Typography> }
                {metric2Desc && <Typography variant="paragraph">{metric2Desc}</Typography>  }
            </PopoverContent>
        </Popover>
    );
}

PopExplanation.propTypes = {
    title: PropTypes.string,
    metric1Title: PropTypes.string,
    metric1Desc: PropTypes.string,
    metric2Title: PropTypes.string,
    metric2Desc: PropTypes.string,
    size: PropTypes.number
};