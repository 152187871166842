import React from "react";
import { IoCodeSlashOutline, IoAccessibilityOutline } from "react-icons/io5";
import PropTypes from "prop-types";
import { AiOutlineApi } from "react-icons/ai";
import { BsWindowFullscreen, BsSpeedometer2 } from "react-icons/bs";
import { PiChartLineLight } from "react-icons/pi";
import { BiDevices } from "react-icons/bi";

import DOMPurify from "dompurify";
import parse from "html-react-parser";

import PopStaticCode from "../../components/cards/shared/popupExplanations/PopStaticCode";
import PopApiIntegration from "../../components/cards/shared/popupExplanations/PopAPIIntegration";
import PopFunctionalUI from "../../components/cards/shared/popupExplanations/PopFunctionalUI";
import PopPerformance from "../../components/cards/shared/popupExplanations/PopPerformance";
import PopAccessibility from "../../components/cards/shared/popupExplanations/PopAccessibility";
import PopCompatibility from "../../components/cards/shared/popupExplanations/PopCompatibility";

//PRIORITY
// Priority Weights
export const priorityWeights = {
  SMOKE: 10,
  P1: 9,
  P2: 8,
  P3: 7,
  P4: 6,
  P5: 5,
  P6: 4,
  P7: 3,
  HIGH: 3,
  MEDIUM: 2,
  LOW: 1,
};

export const priorityColourMap = {
  HIGH: "pink",
  SMOKE: "red",
  P1: "pink",
  MEDIUM: "orange",
  P2: "orange",
  LOW: "blue",
  P3: "yellow",
  P4: "blue",
};

// Function to get severity colour
// If the severity isn't set, potentially because the item did not have a severity value, default to blue
export const getPriorityColour = (priority) => {
  return priorityColourMap[priority.toUpperCase()] || "blue";
};

// Function to get priority weight
// If the priority isn't set, potentially because the item did not have a priority tag, default to 1
export const getPriorityWeight = (priority) => {
  // Check if the priority is defined and has a valid value in the mapping
  if (priority && priorityWeights.hasOwnProperty(priority.toUpperCase())) {
    return priorityWeights[priority.toUpperCase()];
  }
  // Return the default weight if priority is undefined, null, or not found in the mapping
  return 1;
};

//END PRIORITY

// Function to process tags from SauceLabs API and return a flattened object
export const processTags = (tags) => {
  const flattenedTags = {};
  tags.forEach((tag) => {
    const [tagName, tagValue] = tag.split(":");
    const formattedTagValue = tagValue.replace(/-/g, " ");
    if (tagName && tagValue) {
      flattenedTags[tagName.toLowerCase()] = formattedTagValue;
    }
  });
  return flattenedTags;
};

// Helper function to convert a string to camel case
export const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .split(/[^a-zA-Z0-9]+/) // Split the string by non-alphanumeric characters
    .map((word, index) => {
      if (index === 0) return word; // First word should remain lowercase
      return word.charAt(0).toUpperCase() + word.slice(1); // Capitalise the first character of each subsequent word
    })
    .join("");
};

// STATIC CODE ANALYSIS

// Mappings of severity to color
export const severityColourMap = {
  BLOCKER: "red",
  CRITICAL: "pink",
  MAJOR: "orange",
  MINOR: "yellow",
  INFO: "blue",
};

// Function to get severity colour
// If the severity isn't set, potentially because the item did not have a severity value, default to blue
export const getSeverityColour = (severity) => {
  return severityColourMap[severity] || "blue";
};

//Analyse issues and return a rating
export const evaluateCategorySeverityRating = (issues) => {
  if (issues.some((issue) => issue.severity === "BLOCKER")) return 5;
  if (issues.some((issue) => issue.severity === "CRITICAL")) return 4;
  if (issues.some((issue) => issue.severity === "MAJOR")) return 3;
  if (issues.some((issue) => issue.severity === "MINOR")) return 2;
  return 1; // Default for no issues or only INFO severity issues
};

//Assign a numeric value to each severity
const severityRanking = {
  BLOCKER: 1,
  CRITICAL: 2,
  MAJOR: 3,
  MINOR: 4,
  INFO: 5,
};

//Sort issues by severity
export const sortIssuesBySeverity = (a, b) => {
  return severityRanking[a.severity] - severityRanking[b.severity];
};

// Mappings of severity to colour
export const accessibilityViolationColours = {
  critical: "red",
  serious: "orange",
  moderate: "yellow",
  minor: "gray",
};

// Function to get violation colour
// If the severity isn't set, potentially because the item did not have a severity value, default to grey
export const getAccessibilityViolationColours = (severity) => {
  return accessibilityViolationColours[severity] || "gray";
};

// Feature Details Mapping
export const featureDetails = {
  0: {
    icon: PiChartLineLight,
    title: "Overview",
    description:
      "Get a high-level overview of your application and its test results.",
  },
  1: {
    icon: IoCodeSlashOutline,
    title: "Static Code Analysis",
    description:
      "Analyse your codebase for potential vulnerabilities and code quality issues.",
    popover: PopStaticCode,
  },
  2: {
    icon: AiOutlineApi,
    title: "API Integration",
    description:
      "Manage and test the integration points between your application and external APIs.",
    popover: PopApiIntegration,

    //Removed https://app.eu-central-1.saucelabs.com/tests/{id} from detailLink to prevent the link appearing as
    //it is not supported currently in Azure DevOps
    detailLink: "",
  },
  3: {
    icon: BsWindowFullscreen,
    title: "Functional UI",
    description:
      "Test and ensure that the user interface behaves as expected across different scenarios.",
    popover: PopFunctionalUI,

    //Removed https://app.eu-central-1.saucelabs.com/tests/{id} from detailLink to prevent the link appearing as
    //it is not supported currently in Azure DevOps
    detailLink: "",
  },
  4: {
    icon: BsSpeedometer2,
    title: "Performance",
    description:
      "Monitor and optimise the performance of your application under various conditions.",
    popover: PopPerformance,
  },
  5: {
    icon: IoAccessibilityOutline,
    title: "Accessibility",
    description:
      "Evaluate your application for accessibility compliance and identify potential improvements.",
    popover: PopAccessibility,

    //Removed https://app.eu-central-1.saucelabs.com/tests/{id} from detailLink to prevent the link appearing as
    //it is not supported currently in Azure DevOps
    detailLink: "",
  },
  6: {
    icon: BiDevices,
    title: "Compatibility",
    description:
      "Ensure that your application is compatible with multiple devices and browsers.",
    popover: PopCompatibility,

    //Removed https://app.eu-central-1.saucelabs.com/tests/{id} from detailLink to prevent the link appearing as
    //it is not supported currently in Azure DevOps
    detailLink: "",
  },
};

export const testResultStatus = {
  success: "Test has completed successfully.",
  error: "The test has failed to complete.",
  pending: "Testing is in progress.",
  ready_to_collect: "Testing has completed and results are being collected.",
};

export const getStatusDescription = (status) => {
  const statusDetails = {
    pending: { description: "In progress", colour: "yellow" },
    data_collecting: { description: "Collecting data", colour: "yellow" },
    long_running: { description: "Long running", colour: "yellow" },
    success: { description: "Completed", colour: "green" },
    error: { description: "Failed", colour: "pink" },
  };

  return statusDetails[status] || { description: "Unknown", colour: "gray" };
};

export const normaliseData = (data, fields) => {
  const normalisedData = {};
  fields.forEach((field) => {
    normalisedData[field] = data[field] || ""; // Normalise null or undefined to ''
  });
  return normalisedData;
};

/**
 * Converts a given date to the users local timezone
 *
 * @param {Date} pDate - The date to be converted.
 * @param {boolean} [forDisplay=false] - Indicates whether the date should be formatted for display purposes or for use in a HTML textinput.
 * @param {boolean} [includeTime=false] - Include a time component in the formatted date
 * @returns {string} - The converted date in the specified format.
 */
export const convertDateToLocal = (
  pDate,
  forDisplay = false,
  includeTime = false
) => {
  const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Options for date and possibly time
  const options = {
    timeZone: userTimeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // Include time in the options if requested
  if (includeTime) {
    Object.assign(options, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }

  // Create a formatter for date and possibly time
  const dateFormatter = new Intl.DateTimeFormat(userLocale, options);

  if (forDisplay) {
    // Return formatted date and possibly time
    return dateFormatter.format(new Date(pDate));
  } else {
    // If for HTML input, format parts to ensure the format is yyyy-mm-dd or yyyy-mm-ddTHH:MM:SS
    const parts = dateFormatter.formatToParts(new Date(pDate));
    const year = parts.find((part) => part.type === "year")?.value;
    const month = parts.find((part) => part.type === "month")?.value;
    const day = parts.find((part) => part.type === "day")?.value;
    let formattedDate = `${year}-${month}-${day}`;

    if (includeTime) {
      const hour = parts.find((part) => part.type === "hour")?.value;
      const minute = parts.find((part) => part.type === "minute")?.value;
      const second = parts.find((part) => part.type === "second")?.value;
      formattedDate += `T${hour}:${minute}:${second}`;
    }

    return formattedDate;
  }
};

export const getTimeDifference = (date1, date2) => {
  // Parse dates
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Calculate difference in milliseconds
  const diff = Math.abs(d2 - d1);

  // Convert to seconds, minutes, hours
  const seconds = Math.floor((diff / 1000) % 60);
  const minutes = Math.floor((diff / (1000 * 60)) % 60);
  const hours = Math.floor(diff / (1000 * 60 * 60));

  // Format with leading zeros where necessary
  const formattedSeconds = `${seconds} sec`;
  const formattedMinutes = `${minutes} min`;
  const formattedHours = `${hours} hr`;

  // Conditionally append hours if it is non-zero
  if (hours !== 0) {
    return `${formattedHours} ${formattedMinutes} ${formattedSeconds}`;
  } else if (minutes !== 0) {
    return `${formattedMinutes} ${formattedSeconds}`;
  } else {
    return `${formattedSeconds}`;
  }
};

// Helper function to format singular and plural time units
const formatTimeUnit = (value, singular, plural) => {
  return `${value} ${value === 1 ? singular : plural}`;
};

export const formatMinutes = (minutes) => {
  // Convert minutes to days, hours, and minutes
  const days = Math.floor(minutes / 1440); // 1440 minutes in a day
  const hours = Math.floor((minutes % 1440) / 60);
  const mins = minutes % 60;

  const result = [];

  // Add days to the result if applicable
  if (days > 0) {
    result.push(formatTimeUnit(days, "day", "days"));
  }

  // Add hours to the result if applicable
  if (hours > 0) {
    result.push(formatTimeUnit(hours, "hour", "hours"));
  }

  // Add minutes to the result if applicable
  if (mins > 0 || minutes < 60) {
    result.push(formatTimeUnit(mins, "minute", "minutes"));
  }

  // Join the result array with spaces and return the formatted string
  return result.join(" ");
};

export const createSafeMarkup = (html) => {
  // First sanitise the HTML
  const sanitisedHtml = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ["b", "i", "br"],
  });

  // Parse the sanitised HTML into React nodes
  const parsedContent = parse(sanitisedHtml);

  // Wrap in a container div to ensure single element and standardise ouput
  return React.createElement("span", null, parsedContent);
};

export const isDarkMode = () => {
  return document.body.classList.contains("dark");
};

/*
 * Standardise the totals object to ensure it contains the required field names
 * and default values for any missing fields. This function is idempotent and
 * will return the object as is if it already contains the standardised fields.
 * This function handles old SauceLabs field names and new standardised field names.
 *
 * @param {object} dataObject - The object to standardise
 * @returns {object} - The standardised object
 */
export const standardiseTotals = (dataObject) => {
  if (!dataObject || typeof dataObject !== "object") {
    // Return default empty object
    return {
      Passed: 0,
      Failed: 0,
      Errors: 0,
      Others: 0,
    };
  }

  // Check if the object already contains the new standardised fields
  const hasNewFields = ["Passed", "Failed", "Errors", "Others"].every(
    (field) => field in dataObject
  );

  if (hasNewFields) {
    // Object already contains the new fields, return as is
    return dataObject;
  }

  // Define a mapping from old field names to standardised field names
  const fieldMapping = {
    // Old field names mapping to new standardised field names
    passed: "Passed",
    failed: "Failed",
    errors: "Errors",
    others: "Others",
    totalpassed: "Passed",
    totalfailed: "Failed",
    totalerrors: "Errors",
    totalotheroutcomes: "Others",
    totalothers: "Others",
  };

  const standardisedData = {};

  // Iterate over the keys in the dataObject
  Object.keys(dataObject).forEach((key) => {
    // Normalise the key to lowercase for case-insensitive comparison
    const normalisedKey = key.toLowerCase();

    // Determine the standardised key using the mapping
    const standardisedKey = fieldMapping[normalisedKey] || key;

    // Assign the value to the standardised key in the result
    standardisedData[standardisedKey] = dataObject[key];
  });

  // Ensure all standard fields are present with default values
  return {
    Passed: standardisedData.Passed || 0,
    Failed: standardisedData.Failed || 0,
    Errors: standardisedData.Errors || 0,
    Others: standardisedData.Others || 0,
    ...standardisedData, // Include any other fields
  };
};

/*
 * Create a tooltip content string for the other outcomes in the data object.
 * The outcomesObject should be an object where the keys are the outcome names
 * and the values are the counts of each outcome.
 * @param {object} outcomesObject - The object containing the outcomes and counts
 * @returns {string} - The tooltip content string
 */
export const getOtherOutcomesTooltip = (outcomesObject) => {
  if (
    !outcomesObject ||
    typeof outcomesObject !== "object" ||
    Object.keys(outcomesObject).length === 0
  ) {
    return "No other outcomes";
  }

  // Create a list of the other outcomes
  const otherOutcomesList = Object.entries(outcomesObject)
    .map(([outcome, count]) => `${outcome}: ${count}`)
    .join("\n");

  return otherOutcomesList;
};

export const sortArrayAlphabetically = (options = []) => {
  return options
    .filter((option) => option != null) // Remove null/undefined values
    .sort((a, b) => {
      const aString = a || "";
      const bString = b || "";
      if (aString === "Unknown") return 1; // Move "Unknown" to the end
      if (bString === "Unknown") return -1;
      return aString.localeCompare(bString); // Alphabetical sort
    });
};

// Prop Types
export const propTypes = {
  priority: PropTypes.oneOf(["HIGH", "MEDIUM", "LOW"]),
  tags: PropTypes.arrayOf(PropTypes.string),
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      severity: PropTypes.oneOf([
        "BLOCKER",
        "CRITICAL",
        "MAJOR",
        "MINOR",
        "INFO",
      ]),
    })
  ),
  status: PropTypes.oneOf([
    "pending",
    "data_collecting",
    "long_running",
    "success",
    "error",
  ]),
  data: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.string),
  pDate: PropTypes.instanceOf(Date),
  forDisplay: PropTypes.bool,
  includeTime: PropTypes.bool,
  date1: PropTypes.instanceOf(Date),
  date2: PropTypes.instanceOf(Date),
  minutes: PropTypes.number,
};

sortArrayAlphabetically.propTypes = {
  options: PropTypes.array,
};

standardiseTotals.propTypes = {
  dataObject: PropTypes.object,
};

getOtherOutcomesTooltip.propTypes = {
  outcomesObject: PropTypes.object,
};