import PopExplanation from './PopExplanation';
import PropTypes from 'prop-types';
export default function PopFunctionalUI({ page = false, size }) {

    return (
        <PopExplanation 
            title="Functional UI" 
            size={size}
            metric1Title={page ? "" : "Pass Rate"}
            metric1Desc={page ? "Focuses on user interface design using straightforward, maintainable methods. It shows how well the interface works for users and the app." : "Indicates the proportion of successful tests. A high rate suggests good system performance, while a low rate may highlight issues needing attention."}
            metric2Title={page ? "" : "Total Tests"}
            metric2Desc={page ? "" : "Shows the total number of executed tests, reflecting the extent of testing coverage and reliability assessment."} />
    );
}

PopFunctionalUI.propTypes = {
    page: PropTypes.bool,
    size: PropTypes.number
}