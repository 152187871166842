import React from 'react';
import PopExplanation from './PopExplanation';
import PropTypes from 'prop-types';
export default function PopStaticCode({ page = false, size }) {

    return (
        <PopExplanation 
            title="Static Code Analysis" 
            size={size}
            metric1Title={page ? "" : "Status"}
            metric1Desc={page ? "Reviews code for errors before it runs, acting like a quality check. Ensures the code is good and secure." : "The overall status of static code analysis is determined by evaluating each individual metric or measure within the analysis, including code quality, security vulnerabilities, and code complexity, to provide a comprehensive assessment of the code's health and maintainability."}
            metric2Title={page ? "" : "Vulnerability"}
            metric2Desc={page ? "" : "Vulnerability measure evaluates the susceptibility of software code to potential security threats and weaknesses. It helps identify and quantify the likelihood of vulnerabilities, aiding in proactive risk management and secure code development"}
        />
    );
}

PopStaticCode.propTypes = {
    page: PropTypes.bool,
    size: PropTypes.number
}